import { action, computed, persist, thunk } from 'easy-peasy';
import { UserInfoModel, UserModel } from './types';
import { getUser } from '@http/endpoints/user';

const userModel: UserModel = {
  userInfo: {},
  session: persist({ sessionInfo: {}, giftCode: null }, { storage: 'localStorage' }),
  settings: undefined,
  setSettings: action((state, payload) => {
    state.settings = { ...payload };
  }),
  setUser: action((state, payload) => {
    state.userInfo = payload;
  }),
  setSession: action((state, payload) => {
    if (payload) {
      state.session.sessionInfo = {
        refresh_token: payload.refresh_token || '',
        token: payload.token,
      };
    } else {
      state.session.sessionInfo = {};
    }
  }),
  setGiftCode: action((state, payload) => {
    state.session.giftCode = payload;
  }),

  setAuthInfo: thunk(async (actions, payload) => {
    await actions.setSession(payload);
    await actions.updateUser();
  }),
  updateUser: thunk(async (actions, _payload) => {
    getUser().then(async ({ data }: any) => {
      if (data) {
        if (
          data?.data?.data?.user_info?.blocked_from_site === 1 ||
          data?.data?.data?.user_info?.blocked_from_site === '1'
        ) {
          actions.removeAuthInfo();
          window.location.href = 'https://beate-uhse-movie.zendesk.com/hc/de/requests/new';
        } else {
          const temp = {
            ...data.data.data.user,
            ...data.data.data.user_info,
          };
          await actions.setUser(temp);
        }
      }
    });

  }),

  removeAuthInfo: thunk(async (actions) => {
    await actions.setUser({});
    await actions.setSession({});
  }),
  isAuthenticated: computed((state) => {
    if (state.session.sessionInfo.token) return true;
    else return false;
  }),
  isUsedRegistrationPromo: computed((state) => {
    if(!(state?.userInfo as UserInfoModel)?.id) return true;
    return !!((state?.userInfo as UserInfoModel)?.is_used_register_promo);
  }),
  isNewsletterSubscribed: computed((state) => {
    return (state.userInfo as any).is_newsletter_subscribed;
  }),
};

export default userModel;
